<template>
  <div class="subsidiarycompany">
    <div class="banner_container contactus_banner border_text_box">
      <div class="border_text">Subsidiary company</div>
      <h1 class="border_text_title">旗下公司</h1>
    </div>
    <div class="bread_crumbs container_width">
      <span>您的位置：首页 > </span>
      <span class="current">旗下公司</span>
    </div>
    <div class="xianniu_container">
      <div class="xianniu_box border_text_box container_width">
        <h2 class="border_text">Introduction</h2>
        <h3 class="border_text_title">贤牛集团简介</h3>
        <div class="drop_bottom_box" v-bind:class="{ text_fade_in: flag }">
          <div class="bottom_right">
            <div class="title">领跑互联网新储能时代</div>
            <div class="content">
              武汉贤牛科技有限公司2019年2月22日,成立于武汉洪山区，公司办公地点环境优美。且交通便利。武汉海岸跃动网络科技有限公司，以推动效果营销行业发展为己任,专注致力于信息咨询、效果营销、SAS软件服务、跨境电商、进出口贸易,以及各类跨境电商服务解决方案领域,是一家创新型网络科技企业。以前瞻的市场洞悉力,审视效果营销行业发展态势,积聚开拓创新的力量全力挖掘行业内在价值,我们将持续创业的品质融入到自身发展的每个角落,构建了全产业链效果营销服务解决方案体系。最好的产品、最好的服务、最好的信誉，完成众多大型合作配套服务，也愿意成为您最可信赖的长期合作伙伴。
            </div>
            <a href="https://wuhanxianniu.com/" class="a_button">查看更多</a>
          </div>
          <img
            src="../assets/imgs/subsidiarycompany/xianniu_image.webp"
            alt=""
          />
        </div>
        <h2 class="border_text">Subsidiary company</h2>
        <h3 class="border_text_title">旗下公司</h3>
        <div class="Subsidiary_company" v-bind:class="{ text_fade_in: flag1 }">
          <img src="../assets/imgs/subsidiarycompany/haian_image.webp" alt="" />
          <div class="bottom_right">
            <div class="sun_title">数字营销 + 效果营销</div>
            <div class="title">青蓝悦动网络科技有限公司</div>
            <div class="content">
              以跨境电商为主业依托，大力拓展上下游相关产业，并逐步将每个产业发展成为行业领先产业，具备条件的独立上市，利用五至八年时间，将整个产业集团打造成国内百强企业。
            </div>
            <div class="a_colorless_button">
              <a href="https://gremob.com/">访问网站</a>
            </div>
          </div>
        </div>
        <div class="content_line"></div>
        <div class="Subsidiary_company" v-bind:class="{ text_fade_in: flag2 }">
          <div class="bottom_right">
            <div class="sun_title">数字营销 + 效果营销</div>
            <div class="title">哲牛跨境电子商务有限公司</div>
            <div class="content">
              以跨境电商为主业依托，大力拓展上下游相关产业，并逐步将每个产业发展成为行业领先产业，具备条件的独立上市，利用五至八年时间，将整个产业集团打造成国内百强企业。
            </div>
            <div class="a_colorless_button">
              <a href="https://zheniuec.com/">访问网站</a>
            </div>
          </div>
          <img
            src="../assets/imgs/subsidiarycompany/youmen_image.webp"
            alt=""
          />
        </div>
        <!-- <div class="content_line"></div>
            <div class="Subsidiary_company" v-bind:class="{ text_fade_in: flag3 }">
                <img src="../assets/imgs/subsidiarycompany/Slice 141@2x.webp" alt="">
                <div class="bottom_right">
                    <div class="sun_title">数字营销 + 效果营销</div>
                    <div class="title">海岸跃动跨境电子商务</div>
                    <div class="content">以跨境电商为主业依托，大力拓展上下游相关产业，并逐步将每个产业发展成为行业领先产业，具备条件的独立上市，利用五至八年时间，将整个产业集团打造成国内百强企业。</div>
                    <div class="a_colorless_button">
                        <a href="javascript:;">访问网站</a>
                    </div>
                </div>
            </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import "../assets/scss/animation.scss";
import "../assets/scss/communal.scss";
export default {
  name: "subsidiarycompany",
  data() {
    return {
      flag: true,
      flag1: false,
      flag2: false,
      flag3: false,
    };
  },
  mounted() {
    //获取页面可视化高度
    window.addEventListener("scroll", () => {
      //获取页面滚动的高度
      let scrollTop =
        document.body.scrollTop || document.documentElement.scrollTop;
      if (scrollTop > 1200) {
        this.flag1 = true;
      }
      if (scrollTop > 1900) {
        this.flag2 = true;
      }
      if (scrollTop > 2600) {
        this.flag3 = true;
      }
      console.log(scrollTop);
    });
  },
};
</script>

<style lang="scss">
@import "../assets/scss/layout.scss";
.subsidiarycompany {
  .contactus_banner {
    background: url("../assets/imgs/subsidiarycompany/banner.webp") 50% 50%;
    background-size: cover;
    .border_text_title {
      color: #fff;
    }
  }
  .xianniu_container {
    .xianniu_box {
      .drop_bottom_box {
        @include flexrl();
        margin: 80px auto 160px;
        opacity: 0;
        animation-fill-mode: both;
        img {
          width: 50%;
        }
        .bottom_right {
          width: 46%;
          text-align: left;
          .title {
            font-size: 24px;
            font-weight: bold;
            color: #161616;
            margin-bottom: 20px;
          }
          .content {
            font-size: 13px;
            color: #464949;
            line-height: 20px;
            margin-bottom: 90px;
          }
        }
      }
      .Subsidiary_company {
        @include flexrl();
        width: 80%;
        margin: 80px auto 120px;
        opacity: 0;
        animation-fill-mode: both;
        img {
          width: 46%;
        }
        .bottom_right {
          width: 46%;
          text-align: left;
          .sun_title {
            background-image: linear-gradient(95deg, #73e0a9, #5b68df 100%);
            -webkit-background-clip: text;
            color: transparent;
            font-size: 16px;
            font-weight: bold;
            line-height: 26px;
          }
          .title {
            font-size: 24px;
            font-weight: bold;
            color: #161616;
            margin-bottom: 20px;
            position: relative;
            margin-bottom: 60px;
          }
          .title::after {
            content: "";
            position: absolute;
            width: 60px;
            height: 5px;
            bottom: -13px;
            left: 0;
            background: linear-gradient(91deg, #73e0a9, #5b68df 100%);
            border-radius: 50px;
          }
          .content {
            font-size: 13px;
            color: #464949;
            line-height: 20px;
            margin-bottom: 90px;
          }
        }
      }
    }
  }
}
.text_fade_in {
  -webkit-animation: fade-in 1.2s;
  animation: fade-in 1.2s;
  animation-fill-mode: both;
}
@media screen and (max-width: 1160px) {
}
@media screen and (max-width: 820px) {
}
@media screen and (max-width: 768px) {
}
@media screen and (max-width: 425px) {
  .subsidiarycompany {
    .xianniu_container {
      .xianniu_box {
        .drop_bottom_box {
          flex-direction: column;
          margin-bottom: 40px;
          img {
            width: 100%;
          }
          .bottom_right {
            width: 100%;
            text-align: center;
            margin-bottom: 40px;
          }
        }
        .Subsidiary_company {
          width: 90%;
          flex-direction: column;
          margin-bottom: 40px;
          img {
            width: 100%;
            margin-bottom: 40px;
          }
          .bottom_right {
            width: 100%;
            margin-bottom: 40px;
          }
        }
      }
    }
  }
}
</style>
